@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Regular.otf") format("Opentype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-SemiBold.otf") format("Opentype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Metropolis";
  src: url("../fonts/Metropolis-Bold.otf") format("Opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Fonseca";
  src: url("../fonts/Fonseca-Regular.otf") format("Opentype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

body {
  font-family: "Metropolis", Arial, sans-serif;
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Fonseca", "Metropolis", Arial, sans-serif;
}

p {
  font-family: "Metropolis", Arial, sans-serif;
  line-height: 1.5rem;
}

button,
input,
select,
textarea {
  font-family: "Metropolis", Arial, sans-serif;
}
